import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./real-time-collaboration@gen.scss";

const GeneratedProductFeaturesRealTimeCollaboration = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          リアルタイムコラボレーション - PagerDuty正規代理店 - 株式会社Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="人員の招集、コラボレーション、ナレッジ共有"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="人員の招集、コラボレーション、ナレッジ共有"
        />

        <meta
          property="og:title"
          content="リアルタイムコラボレーション - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="人員の招集、コラボレーション、ナレッジ共有"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/real-time-collaboration/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/real-time-collaboration/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="リアルタイムコラボレーション"
        overview=""
      />

      <div className="rtcWrapper">
        <div className="center-2 first-section">
          <h2 className="center-title">
            人員の招集、コラボレーション、ナレッジ共有
          </h2>
          <p className="center-para">
            適切な人々と効果的にコミュニケーションをとることで、より迅速にインシデントを解決します。
            <br />
            オンコールの対応者だけでなく、リアルタイムでインシデントに対処するためのレスポンダーを追加することができます。
            ビジネス全体の関係者を巻き込んでワークフローに取り組み、ビジネスのレスポンスを加速します。
            PagerDutyは、既存のChatOpsツール、会議ソリューション、チケットシステムと統合して、インシデントワークフローを合理化します。
            PagerDutyはコミュニケーションを効率化し、すべての技術チームに重要な問題を知らせます。
            それは私たちの問題を解決しました。
          </p>
        </div>
        {/* details */}
        <div className="center-1">
          <h2 className="center-title">リアルタイムコラボレーションの詳細</h2>
        </div>
        <div className="three-col-without-cta">
          <div className="card">
            <div className="title">
              <p>
                ステークホルダー
                <br />
                エンゲージメント
              </p>
            </div>
            <p>
              ステークホルダーユーザーライセンスでは、組織全体のステークホルダーが、重要なインシデントの通知をリアルタイムに受け取ることができます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>ChatOpsクライアントの統合</p>
            </div>
            <p>
              HipChat、SlackなどのポピュラーなChatOpsツールとネイティブの統合し、チャットチャンネルにアラートを送信します、また、チャットチャンネルで、認識、解決することができます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>レスポンスブリッジ</p>
            </div>
            <p>
              リアルタイムに重要なインシデントを解決するために、さまざまな会議システム（WebEx、Blue
              Jeans、GoToMeeting）の情報をを直接含めることができます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>レスポンダーの招待</p>
            </div>
            <p>
              個人やエスカレーションポリシーを指定して、個人をダイナミックに招待し、PagerDutyのあらゆるインシデントに直接コラボレーションします。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>チケットシステム統合</p>
            </div>
            <p>
              任意のチケットシステムと統合して、アラートからチケットを自動的に作成し、ステータス、監査履歴、および2者間のインシデントの詳細を同期させます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>インシデントの優先順位</p>
            </div>
            <p>
              組織の優先順位体系（P1-P5、SEV1-SEV5など）に一致するカスタム定義レベルで、PagerDutyインシデントを簡単に分類します。
            </p>
          </div>
        </div>
        <div className="conclusion">
          PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
          <br />
          インシデントをより迅速に解決するのに役立ちます。
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesRealTimeCollaboration;
